import AnimationLoader from '../preloaders/animations/AnimationLoader';
import eAnimationType from '../preloaders/animations/eAnimationType';
import ImagePreloader from '../preloaders/images/ImagePreloader';
import * as PIXI from 'pixi.js';

export default class ControllerCrate {
  constructor(x, y, container) {
    this.destroyed = false;
    // const index = Math.random() > 0.5 ? 1 : 0;
    this.image = ImagePreloader.createImage(`crate_${0}`);
    this.image.x = x;
    this.image.y = y;
    this.animation = AnimationLoader.createAnimation(eAnimationType.EAT_CRATE);
    this.animation.x = x - 51;
    this.animation.y = y - 177;
    this.animation.visible = false;

    this.container = new PIXI.Container();
    container.addChild(this.container);

    const complete = this.destroy.bind(this);
    this.animation.state.addListener({ complete });

    this.container.addChild(this.image);
    this.container.addChild(this.animation);
  }

  showAnimationDestroy(content) {
    if (content === 0) {
      this.destroy();
      return;
    }
    this.image.visible = false;
    this.animation.visible = true;
    // const name = content === 1 ? 'x1.2' : 'jackpot_box';
    this.animation.state.setAnimation(0, 'x1.2', false);
    // this.animation.state.addAnimation(0, 'jackpot_top', false, 0);
    // this.animation.state.addAnimation(0, 'jackpot_idle', false, 0);
  }

  destroy(data) {
    // if (data && (data.animation.name === 'jackpot_box' || data.animation.name === 'jackpot_top')) return;
    if (this.destroyed) return;
    this.destroyed = true;
    this.container.destroy({ children: true });
  }
}
