import bg from '../../../assets/images/bg.webp';
import ground from '../../../assets/images/ground.webp';
import crate0 from '../../../assets/images/crate_0.webp';
import crate1 from '../../../assets/images/crate_1.webp';
import progress from '../../../assets/images/progress.webp';
import progressBg from '../../../assets/images/progress_bg.webp';
import clouds from '../../../assets/images/clouds.webp';
import mount0 from '../../../assets/images/mountain0.webp';
import mount1 from '../../../assets/images/mountain1.webp';
import house0 from '../../../assets/images/house0.webp';
import house1 from '../../../assets/images/house1.webp';
import house2 from '../../../assets/images/house2.webp';
import arena from '../../../assets/images/arena.webp';
import beach from '../../../assets/images/beach.webp';
import palm0 from '../../../assets/images/palm0.webp';
import palm1 from '../../../assets/images/palm1.webp';
import yacht0 from '../../../assets/images/yacht0.webp';
import yacht1 from '../../../assets/images/yacht1.webp';
import sun from '../../../assets/images/sun.webp';

import beer from '../../../assets/images/smiles/beer.webp';
import fire from '../../../assets/images/smiles/fire.webp';
import flushed from '../../../assets/images/smiles/flushed.webp';
import folded_hands from '../../../assets/images/smiles/folded_hands.webp';
import four_leaf_clover from '../../../assets/images/smiles/four_leaf_clover.webp';
import money_mouth from '../../../assets/images/smiles/money_mouth.webp';
import pile_of_poo from '../../../assets/images/smiles/pile_of_poo.webp';
import screaming_in_fear from '../../../assets/images/smiles/screaming_in_fear.webp';
import slot_machine from '../../../assets/images/smiles/slot_machine.webp';
import sunglasses from '../../../assets/images/smiles/sunglasses.webp';
import swearing from '../../../assets/images/smiles/swearing.webp';
import tongue from '../../../assets/images/smiles/tongue.webp';

import openSmiles from '../../../assets/images/smiles/button_open.webp';
import closeSmiles from '../../../assets/images/smiles/button_close.webp';

import eImageType from './eImageType';
import * as PIXI from 'pixi.js';
import eSmileType from './eSmileType';

export default new class ImagePreloader {
  constructor() {
    this.imagesData = [
      { type: eImageType.EIT_BG, src: bg },
      { type: eImageType.EIT_CLOUDS, src: clouds },
      { type: eImageType.EIT_GROUND, src: ground },
      { type: eImageType.EIT_CRATE_0, src: crate0 },
      { type: eImageType.EIT_CRATE_1, src: crate1 },
      { type: eImageType.EIT_PROGRESS, src: progress },
      { type: eImageType.EIT_PROGRESS_BG, src: progressBg },
      { type: eImageType.EIT_PALM_0, src: palm0 },
      { type: eImageType.EIT_PALM_1, src: palm1 },
      { type: eImageType.EIT_MOUNT0, src: mount0 },
      { type: eImageType.EIT_MOUNT1, src: mount1 },
      { type: eImageType.EIT_HOUSE_0, src: house0 },
      { type: eImageType.EIT_HOUSE_1, src: house1 },
      { type: eImageType.EIT_HOUSE_2, src: house2 },
      { type: eImageType.EIT_ARENA, src: arena },
      { type: eImageType.EIT_BEACH, src: beach },
      { type: eImageType.EIT_YACHT0, src: yacht0 },
      { type: eImageType.EIT_YACHT1, src: yacht1 },
      { type: eImageType.EIT_SUN, src: sun },

      { type: eSmileType.EST_BEER, src: beer },
      { type: eSmileType.EST_FIRE, src: fire },
      { type: eSmileType.EST_FLUSHED, src: flushed },
      { type: eSmileType.EST_FOLDED_HANDS, src: folded_hands },
      { type: eSmileType.EST_FOUR_LEAF_CLOVER, src: four_leaf_clover },
      { type: eSmileType.EST_MONEY_MOUTH, src: money_mouth },
      { type: eSmileType.EST_PILE_OF_POO, src: pile_of_poo },
      { type: eSmileType.EST_SCREAMING_IN_FEAR, src: screaming_in_fear },
      { type: eSmileType.EST_SLOT_MACHINE, src: slot_machine },
      { type: eSmileType.EST_SUNGLASSES, src: sunglasses },
      { type: eSmileType.EST_SWEARING, src: swearing },
      { type: eSmileType.EST_TONGUE, src: tongue },

      { type: eImageType.EIT_OPEN_SMILES, src: openSmiles },
      { type: eImageType.EIT_CLOSE_SMILES, src: closeSmiles },
    ];
  }

  addAssetsToBundle(bundleAssets) {
    for (let asset of this.imagesData) {
      bundleAssets[asset.type] = asset.src;
    }
  }

  createImage(type) {
    return new PIXI.Sprite(this.getTexture(type));
  }

  getTexture(type) {
    return PIXI.Assets.get(type);
  }

  getSrc(type) {
    const index = this.imagesData.findIndex((item) => item.type === type);
    if(index === -1){
      console.error(`Unlisted image type: ${type}`)
    }
    return this.imagesData[index].src;
  }
}
