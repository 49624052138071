import eAnimationType from './eAnimationType';
import * as PIXI from "pixi.js";
import { Spine } from 'pixi-spine';

class AnimationsLoader {
  constructor() {
    this.animationsData = [
      { type: eAnimationType.EAT_LAMBO, src: 'assets/animations/lambo_pro_dew/lambo_animations.json' },
      { type: eAnimationType.EAT_CRATE, src: 'assets/animations/lambo_box_pro_dew/lambo_box.json' },
      { type: eAnimationType.EAT_FLAG, src: 'assets/animations/lambo_flag_dew/lambo_flag.json' },
    ];
  }
  addAssetsToBundle(bundleAssets) {
    for (let asset of this.animationsData) {
      bundleAssets[asset.type] = asset.src;
    }
  }

  createAnimation(type) {
    const resource = PIXI.Assets.get(type);
    return new Spine(resource.spineData);
  }
}

export default new AnimationsLoader();
